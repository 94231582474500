 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Button, Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAsync, useLocalStorage } from 'react-use';
import { lighthouseApiRef, } from '../../api';
import { useQuery } from '../../utils';
import LighthouseIntro, { LIGHTHOUSE_INTRO_LOCAL_STORAGE } from '../Intro';
import LighthouseSupportButton from '../SupportButton';
import AuditListTable from './AuditListTable';

import {
  Content,
  ContentHeader,
  Header,
  HeaderLabel,
  InfoCard,
  Page,
  Progress,
  WarningPanel,
} from '@backstage/core-components';

import { useApi } from '@backstage/core-plugin-api';

// TODO(freben): move all of this out of index
export const LIMIT = 100;

const AuditList = () => {
  const [dismissedStored] = useLocalStorage(LIGHTHOUSE_INTRO_LOCAL_STORAGE);
  const [dismissed, setDismissed] = useState(dismissedStored);


  const query = useQuery();
  const page = query.get('page')
    ? parseInt(query.get('page') , 10) || 1
    : 1;

  const lighthouseApi = useApi(lighthouseApiRef);
  const { value, loading, error } = useAsync(
    async () => {  
      // TODO: Serverside should handle the code= cases and group them into one item
      // This will make any groupCode___ functions redundant 
      let value = await lighthouseApi.getWebsiteList({ 
        limit: LIMIT,                                 
        offset: (page - 1) * LIMIT,
      })
      groupCodeUrls(value)
      return value
    },
    [page],
  );
  // A solution to this is to increase the limit to 100. This avoids using the pages. Since any workaround will result in either 
  // weird looking pages (different number of items per page) or different pages holding the same item (code= items)

  const pageCount = useMemo(() => {
    if (_optionalChain([value, 'optionalAccess', _ => _.total]) && _optionalChain([value, 'optionalAccess', _2 => _2.limit]))
      return Math.ceil(_optionalChain([value, 'optionalAccess', _3 => _3.total]) / _optionalChain([value, 'optionalAccess', _4 => _4.limit]));
    return 0;
  }, [_optionalChain([value, 'optionalAccess', _5 => _5.total]), _optionalChain([value, 'optionalAccess', _6 => _6.limit])]);

  const navigate = useNavigate();

  let content = null;
  if (value) {
    content = (
      React.createElement(React.Fragment, null
        , React.createElement(AuditListTable, { items: _optionalChain([value, 'optionalAccess', _7 => _7.items]) || [],} )
        , pageCount > 1 && (
          React.createElement(Pagination, {
            page: page,
            count: pageCount,
            onChange: (_event, newPage) => {
              navigate(`?page=${newPage}`);
            },}
          )
        )
      )
    );
  } else if (loading) {
    content = React.createElement(Progress, null );
  } else if (error) {
    content = (
      React.createElement(WarningPanel, { severity: "error", title: "Could not load audit list."    ,}
        , error.message
      )
    );
  }

  return (
    React.createElement(Page, { themeId: "tool",}
      , React.createElement(Header, {
        title: "Lighthouse",
        subtitle: "Website audits powered by Lighthouse"    ,}
      
        , React.createElement(HeaderLabel, { label: "Owner", value: "Spotify",} )
        , React.createElement(HeaderLabel, { label: "Lifecycle", value: "Alpha",} )
      )
      , React.createElement(Content, null
        , React.createElement(LighthouseIntro, { onDismiss: () => setDismissed(true),} )
        , React.createElement(ContentHeader, {
          title: "Audits",
          description: "View all audits run for your website through Backstage here. Track the trend of your most recent audits."                 ,}
        
          , React.createElement(Button, {
            variant: "contained",
            color: "primary",
            onClick: () => navigate('create-audit'),}
          , "Create Audit"

          )
          , dismissed && React.createElement(LighthouseSupportButton, null )
        )
        , React.createElement(Grid, { container: true, spacing: 3, direction: "column",}
          , React.createElement(Grid, { item: true,}
            , React.createElement(InfoCard, { noPadding: true,}, content)
          )
        )
      )
    )
  );
};

export default AuditList;

// This function could be more useful where the POST is recieved on the server side. 
// With some changes we could group the code= url's together before ever calling the GETs.
function groupCodeUrls(value) {
  let codeArray = []
  codeArray = value.items.filter(item => item.url.includes('code='));
  // Removes all the items that had code= in it from the original array
  codeArray.forEach(f => value.items.splice(value.items.findIndex(e => e.url === f.url),1));

  for (const item of codeArray) {
    const index = value.items.findIndex(value => value.url.split('/?')[0] === item.url.split('/?')[0]);
    if (index !== -1) {
      value.items[index].audits = value.items[index].audits.concat(item.audits);
      if (new Date(value.items[index].lastAudit.timeCreated).getTime() < new Date(item.lastAudit.timeCreated).getTime()) {
        value.items[index].lastAudit = item.lastAudit;
      }
    }
    else {
      value.items.push(item);
    }
  }
}
