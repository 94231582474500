
















import { createApiRef } from '@backstage/core-plugin-api';





































































export class FetchError extends Error {
  get name() {
    return this.constructor.name;
  }

  static async forResponse(resp) {
    return new FetchError(
      `Request failed with status code ${
        resp.status
      }.\nReason: ${await resp.text()}`,
    );
  }
}









export const lighthouseApiRef = createApiRef({
  id: 'plugin.lighthouse.service',
  description: 'Used by the Lighthouse plugin to make requests',
});

export class LighthouseRestApi  {
  static fromConfig(config) {
    return new LighthouseRestApi(
      config.getString('lighthouse.baseUrl'),
      config.getOptionalString('lighthouse.authorization')
    );
  }

  constructor( url,  auth) {;this.url = url;this.auth = auth;}

   async fetch(input, init) {
    if (this.auth) {
      if (init){
        let currentHeader = new Headers(init.headers);
        currentHeader.append("Authorization", `Basic ${this.auth}`);
        init.headers = currentHeader;
      }
      else {
        init = {headers: {"Authorization": `Basic ${this.auth}`} }
      }
    }
    const resp = await fetch(`${this.url}${input}`, init);
    if (!resp.ok) throw await FetchError.forResponse(resp);
    return await resp.json();
  }

  async getWebsiteList({ 
    limit,
    offset,
  } = {}) {
    const params = new URLSearchParams();
    if (typeof limit === 'number') params.append('limit', limit.toString());
    if (typeof offset === 'number') params.append('offset', offset.toString());
    return await this.fetch(
      `/v1/websites?${params.toString()}`
    );
  }

  async getWebsiteForAuditId(auditId) { 
    return await this.fetch(
      `/v1/audits/${encodeURIComponent(auditId)}/website`
    );
  }

  async triggerAudit(payload) {
    return await this.fetch('/v1/audits', {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      },
    });
  }

  async getWebsiteByUrl(websiteUrl) {
    return this.fetch(
      `/v1/websites/${encodeURIComponent(websiteUrl)}`
    );
  }
}
