 function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { useEntity } from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';
import { LIGHTHOUSE_WEBSITE_URL_ANNOTATION } from '../constants';
import { lighthouseApiRef, } from '../api';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';

// For the sake of simplicity we assume that an entity has only one website url. This is to avoid encoding a list
// type in an annotation which is a plain string.
export const useWebsiteForEntity = () => {
  const errorApi = useApi(errorApiRef);
  const lighthouseApi = useApi(lighthouseApiRef);
  let value;

  const { entity } = useEntity();
  let websiteUrl =
    _nullishCoalesce(_optionalChain([entity, 'access', _ => _.metadata, 'access', _2 => _2.annotations, 'optionalAccess', _3 => _3[LIGHTHOUSE_WEBSITE_URL_ANNOTATION]]), () => ( ''));

  const { value: websiteList, loading, error } = useAsync(
    async () =>
      await lighthouseApi.getWebsiteList({
        limit: 100,
        offset: 0,
      }),
    [],
  );

  // This will always run
  if (websiteList) {
    value = getGroupCodeItem(websiteList, websiteUrl);
  }

  // const response = useAsync(() => lighthouseApi.getWebsiteByUrl(websiteUrl), [
  //   websiteUrl
  // ]);

  if (error) {
    errorApi.post(error);
  } 
  return {value, loading, error};  
};

function getGroupCodeItem(value, websiteUrl)  {
  let codeArray = []
  codeArray = value.items.filter(item => item.url.includes('code='));
  // Removes all the items that had code= in it from the original array
  codeArray.forEach(f => value.items.splice(value.items.findIndex(e => e.url === f.url),1));

  for (const item of codeArray) {
    const index = value.items.findIndex(value => value.url.split('/?')[0] === item.url.split('/?')[0]);
    if (index !== -1) {
      value.items[index].audits = value.items[index].audits.concat(item.audits);
      if (new Date(value.items[index].lastAudit.timeCreated).getTime() < new Date(item.lastAudit.timeCreated).getTime()) {
        value.items[index].lastAudit = item.lastAudit;
      }
    }
    else {
      value.items.push(item);
    }
  }
  for (const item of value.items) {
    for (const audit of item.audits) {
      if (audit.url.includes(websiteUrl)) {
        return item
      }
    }
  }
  return undefined
}
