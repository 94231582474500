/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { EmptyState } from '@backstage/core-components';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntitySystemDiagramCard,
  EntityHasComponentsCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntitySwitch,
  isComponentType,
  isKind,
  EntityLinksCard,
  hasCatalogProcessingErrors,
  EntityProcessingErrorsPanel
} from '@backstage/plugin-catalog';
import {
  isGithubActionsAvailable,
  EntityGithubActionsContent,
} from '@backstage/plugin-github-actions';
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { EntityJiraOverviewCard, isJiraAvailable } from '@roadiehq/backstage-plugin-jira';
import { EntitySonarQubeCard, isSonarQubeAvailable } from '@backstage/plugin-sonarqube';
import { EntityCircleCIContent, isCircleCIAvailable } from '@backstage/plugin-circleci';
import { 
  EntityGithubInsightsContent, 
  isGithubInsightsAvailable, 
  EntityGithubInsightsReadmeCard 
} from '@roadiehq/backstage-plugin-github-insights';
import { EntityGithubPullRequestsContent } from '@roadiehq/backstage-plugin-github-pull-requests';
import { EntityTodoContent } from '@backstage/plugin-todo';
import { GitReleaseManagerPage } from '@backstage/plugin-git-release-manager';
import {
  isPluginApplicableToEntity as isPagerDutyAvailable,
  EntityPagerDutyCard,
} from '@backstage/plugin-pagerduty';
import { 
  EntityLighthouseContent
} from '@mitel/mc-lighthouse';
import {
  EntityDatadogContent,
  EntityDatadogGraphCard,
  isDatadogGraphAvailable
} from '@roadiehq/backstage-plugin-datadog';

const cicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  React.createElement(EntitySwitch, null
    , React.createElement(EntitySwitch.Case, { if: isGithubActionsAvailable,}
      , React.createElement(EntityGithubActionsContent, null )
    )
    , React.createElement(EntitySwitch.Case, { if: isCircleCIAvailable,}
      , React.createElement(EntityCircleCIContent, null )
    )

    , React.createElement(EntitySwitch.Case, null
      , React.createElement(EmptyState, {
        title: "No CI/CD available for this entity"     ,
        missing: "info",
        description: "You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."                             ,
        action: 
          React.createElement(Button, {
            variant: "contained",
            color: "primary",
            href: "https://backstage.io/docs/features/software-catalog/well-known-annotations",}
          , "Read more"

          )
        ,}
      )
    )
  )
);

const overviewContent = (
  React.createElement(Grid, { container: true, spacing: 3, alignItems: "stretch",}
    , React.createElement(Grid, { item: true, md: 6,}
      , React.createElement(EntityAboutCard, { variant: "gridItem",} )
    )

    , React.createElement(EntitySwitch, null
      , React.createElement(EntitySwitch.Case, { if: hasCatalogProcessingErrors,}
        , React.createElement(Grid, { item: true, xs: 12,}
          , React.createElement(EntityProcessingErrorsPanel, null )
        )
      )
    )

    , React.createElement(EntitySwitch, null
      , React.createElement(EntitySwitch.Case, { if: isSonarQubeAvailable,}
        , React.createElement(Grid, { item: true, md: 6,}
          , React.createElement(EntitySonarQubeCard, { variant: "gridItem",} )
        )
      )
    )

    , React.createElement(EntitySwitch, null
      , React.createElement(EntitySwitch.Case, { if: isJiraAvailable,}
        , React.createElement(Grid, { item: true, md: 6,}
          , React.createElement(EntityJiraOverviewCard, null )
        )
      )
    )

    , React.createElement(EntitySwitch, null
      , React.createElement(EntitySwitch.Case, { if: isGithubInsightsAvailable,}
        , React.createElement(Grid, { item: true, md: 6,}
          , React.createElement(EntityGithubInsightsReadmeCard, { maxHeight: 350,} )
        )
      )
    )

    , React.createElement(EntitySwitch, null
      , React.createElement(EntitySwitch.Case, { if: isPagerDutyAvailable,}
        , React.createElement(Grid, { item: true, md: 6,}
          , React.createElement(EntityPagerDutyCard, null )
        )
      )
    )

    , React.createElement(EntitySwitch, null
      , React.createElement(EntitySwitch.Case, { if: isDatadogGraphAvailable,}
        , React.createElement(Grid, { item: true,}
         , React.createElement(EntityDatadogGraphCard, null)
        )
      )
    )

    , React.createElement(Grid, { item: true, md: 4, sm: 6,}
      , React.createElement(EntityLinksCard, null )
    )
  )
);

const serviceEntityPage = (
  React.createElement(EntityLayout, null
    , React.createElement(EntityLayout.Route, { path: "/", title: "Overview",}
      , overviewContent
    )

    , React.createElement(EntityLayout.Route, { path: "/ci-cd", title: "CI/CD",}
      , cicdContent
    )

    , React.createElement(EntityLayout.Route, { path: "/code-insights", title: "Code Insights" ,}
      , React.createElement(EntityGithubInsightsContent, null )
    )

    , React.createElement(EntityLayout.Route, { path: "/pull-requests", title: "Pull Requests" ,}
      , React.createElement(EntityGithubPullRequestsContent, null )
    )

    , React.createElement(EntityLayout.Route, { path: "/git-release-manager", title: "Git Release Manager"  ,}
      , React.createElement(GitReleaseManagerPage, null )
    )

    , React.createElement(EntityLayout.Route, { path: "/todo", title: "Todo",}
      , React.createElement(EntityTodoContent, null )
    )

    , React.createElement(EntityLayout.Route, { path: "/api", title: "API",}
      , React.createElement(Grid, { container: true, spacing: 3, alignItems: "stretch",}
        , React.createElement(Grid, { item: true, md: 6,}
          , React.createElement(EntityProvidedApisCard, null )
        )
        , React.createElement(Grid, { item: true, md: 6,}
          , React.createElement(EntityConsumedApisCard, null )
        )
      )
    )

    , React.createElement(EntityLayout.Route, { path: "/datadog", title: "Datadog",}
      , React.createElement(EntityDatadogContent, null )
    )

    , React.createElement(EntityLayout.Route, { path: "/docs", title: "Docs",}
      , React.createElement(EntityTechdocsContent, null )
    )
  )
);

const websiteEntityPage = (
  React.createElement(EntityLayout, null
    , React.createElement(EntityLayout.Route, { path: "/", title: "Overview",}
      , overviewContent
    )

    , React.createElement(EntityLayout.Route, { path: "/ci-cd", title: "CI/CD",}
      , cicdContent
    )

    , React.createElement(EntityLayout.Route, { path: "/lighthouse", title: "Lighthouse",}
      , React.createElement(EntityLighthouseContent, null )
    )

    , React.createElement(EntityLayout.Route, { path: "/docs", title: "Docs",}
      , React.createElement(EntityTechdocsContent, null )
    )
  )
);

const defaultEntityPage = (
  React.createElement(EntityLayout, null
    , React.createElement(EntityLayout.Route, { path: "/", title: "Overview",}
      , overviewContent
    )

    , React.createElement(EntityLayout.Route, { path: "/docs", title: "Docs",}
      , React.createElement(EntityTechdocsContent, null )
    )
  )
);

const componentPage = (
  React.createElement(EntitySwitch, null
    , React.createElement(EntitySwitch.Case, { if: isComponentType('service'),}
      , serviceEntityPage
    )

    , React.createElement(EntitySwitch.Case, { if: isComponentType('website'),}
      , websiteEntityPage
    )

    , React.createElement(EntitySwitch.Case, null, defaultEntityPage)
  )
);

const apiPage = (
  React.createElement(EntityLayout, null
    , React.createElement(EntityLayout.Route, { path: "/", title: "Overview",}
      , React.createElement(Grid, { container: true, spacing: 3,}
        , React.createElement(Grid, { item: true, md: 6,}
          , React.createElement(EntityAboutCard, null )
        )
        , React.createElement(Grid, { item: true, md: 4, sm: 6,}
          , React.createElement(EntityLinksCard, null )
        )
        , React.createElement(Grid, { container: true, item: true, md: 12,}
          , React.createElement(Grid, { item: true, md: 6,}
            , React.createElement(EntityProvidingComponentsCard, null )
          )
          , React.createElement(Grid, { item: true, md: 6,}
            , React.createElement(EntityConsumingComponentsCard, null )
          )
        )
      )
    )

    , React.createElement(EntityLayout.Route, { path: "/definition", title: "Definition",}
      , React.createElement(Grid, { container: true, spacing: 3,}
        , React.createElement(Grid, { item: true, xs: 12,}
          , React.createElement(EntityApiDefinitionCard, null )
        )
      )
    )
  )
);

const userPage = (
  React.createElement(EntityLayout, null
    , React.createElement(EntityLayout.Route, { path: "/", title: "Overview",}
      , React.createElement(Grid, { container: true, spacing: 3,}
        , React.createElement(Grid, { item: true, xs: 12, md: 6,}
          , React.createElement(EntityUserProfileCard, { variant: "gridItem",} )
        )
        , React.createElement(Grid, { item: true, xs: 12, md: 6,}
          , React.createElement(EntityOwnershipCard, { variant: "gridItem",} )
        )
      )
    )
  )
);

const groupPage = (
  React.createElement(EntityLayout, null
    , React.createElement(EntityLayout.Route, { path: "/", title: "Overview",}
      , React.createElement(Grid, { container: true, spacing: 3,}
        , React.createElement(Grid, { item: true, xs: 12, md: 6,}
          , React.createElement(EntityGroupProfileCard, { variant: "gridItem",} )
        )
        , React.createElement(Grid, { item: true, xs: 12, md: 6,}
          , React.createElement(EntityOwnershipCard, { variant: "gridItem",} )
        )
        , React.createElement(Grid, { item: true, xs: 12,}
          , React.createElement(EntityMembersListCard, null )
        )
      )
    )
  )
);

const systemPage = (
  React.createElement(EntityLayout, null
    , React.createElement(EntityLayout.Route, { path: "/", title: "Overview",}
      , React.createElement(Grid, { container: true, spacing: 3, alignItems: "stretch",}
        , React.createElement(Grid, { item: true, md: 6,}
          , React.createElement(EntityAboutCard, { variant: "gridItem",} )
        )
        , React.createElement(Grid, { item: true, md: 6,}
          , React.createElement(EntityHasComponentsCard, { variant: "gridItem",} )
        )
        , React.createElement(Grid, { item: true, md: 6,}
          , React.createElement(EntityHasApisCard, { variant: "gridItem",} )
        )
      )
    )
    , React.createElement(EntityLayout.Route, { path: "/diagram", title: "Diagram",}
      , React.createElement(EntitySystemDiagramCard, null )
    )
  )
);

const domainPage = (
  React.createElement(EntityLayout, null
    , React.createElement(EntityLayout.Route, { path: "/", title: "Overview",}
      , React.createElement(Grid, { container: true, spacing: 3, alignItems: "stretch",}
        , React.createElement(Grid, { item: true, md: 6,}
          , React.createElement(EntityAboutCard, { variant: "gridItem",} )
        )
        , React.createElement(Grid, { item: true, md: 6,}
          , React.createElement(EntityHasSystemsCard, { variant: "gridItem",} )
        )
      )
    )
  )
);

export const entityPage = (
  React.createElement(EntitySwitch, null
    , React.createElement(EntitySwitch.Case, { if: isKind('component'), children: componentPage,} )
    , React.createElement(EntitySwitch.Case, { if: isKind('api'), children: apiPage,} )
    , React.createElement(EntitySwitch.Case, { if: isKind('group'), children: groupPage,} )
    , React.createElement(EntitySwitch.Case, { if: isKind('user'), children: userPage,} )
    , React.createElement(EntitySwitch.Case, { if: isKind('system'), children: systemPage,} )
    , React.createElement(EntitySwitch.Case, { if: isKind('domain'), children: domainPage,} )

    , React.createElement(EntitySwitch.Case, null, defaultEntityPage)
  )
);
