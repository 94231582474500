/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from 'react';

import { useWebsiteForEntity } from '../../hooks/useWebsiteForEntity';
import AuditStatusIcon from '../AuditStatusIcon';
import {
  InfoCard,

  Progress,
  StatusError,
  StatusOK,
  StatusWarning,
  StructuredMetadataTable,
} from '@backstage/core-components';

const LighthouseCategoryScoreStatus = ({ score }) => {
  const scoreAsPercentage = Math.round(score * 100);
  switch (true) {
    case scoreAsPercentage >= 90:
      return (
        React.createElement(React.Fragment, null
          , React.createElement(StatusOK, null )
          , scoreAsPercentage, "%"
        )
      );
    case scoreAsPercentage >= 50 && scoreAsPercentage < 90:
      return (
        React.createElement(React.Fragment, null
          , React.createElement(StatusWarning, null )
          , scoreAsPercentage, "%"
        )
      );
    case scoreAsPercentage < 50:
      return (
        React.createElement(React.Fragment, null
          , React.createElement(StatusError, null )
          , scoreAsPercentage, "%"
        )
      );
    default:
      return React.createElement('span', null, "N/A");
  }
};

const LighthouseAuditStatus = ({ audit }) => (
  React.createElement(React.Fragment, null
    , React.createElement(AuditStatusIcon, { audit: audit,} )
    , audit.status.toLocaleUpperCase('en-US')
  )
);

const LighthouseAuditSummary = ({
  audit,
  dense = false,
}


) => {
  const { url } = audit;
  const flattenedCategoryData = {};
  if (audit.status === 'COMPLETED') {
    const categories = (audit ).categories;
    const categoryIds = Object.keys(categories) ;
    categoryIds.forEach((id) => {
      const { title, score } = categories[id];

      flattenedCategoryData[title] = (
        React.createElement(LighthouseCategoryScoreStatus, { score: score,} )
      );
    });
  }
  const tableData = {
    url,
    status: React.createElement(LighthouseAuditStatus, { audit: audit,} ),
    ...flattenedCategoryData,
  };

  return React.createElement(StructuredMetadataTable, { metadata: tableData, dense: dense,} );
};

export const LastLighthouseAuditCard = ({
  dense = false,
  variant,
}


) => {
  const { value: website, loading, error } = useWebsiteForEntity();

  let content;
  if (loading) {
    content = React.createElement(Progress, null );
  }
  if (error) {
    content = null;
  }
  if (website) {
    content = (
      React.createElement(LighthouseAuditSummary, { audit: website.lastAudit, dense: dense,} )
    );
  }
  return (
    React.createElement(InfoCard, { title: "Lighthouse Audit" , variant: variant,}
      , content
    )
  );
};
