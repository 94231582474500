 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import {
  generatePath,
  Link,
  resolvePath,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useAsync } from 'react-use';
import { lighthouseApiRef, } from '../../api';
import { formatTime } from '../../utils';
import AuditStatusIcon from '../AuditStatusIcon';
import LighthouseSupportButton from '../SupportButton';

import {
  Content,
  ContentHeader,
  Header,
  HeaderLabel,
  InfoCard,
  Page,
  Progress,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';

// TODO(freben): move all of this out of index

const useStyles = makeStyles({
  contentGrid: {
    height: '100%',
  },
  iframe: {
    border: '0',
    width: '100%',
    height: '100%',
  },
  errorOutput: { whiteSpace: 'pre' },
});





const AuditLinkList = ({ audits = [], selectedId }) => (
  React.createElement(List, {
    'data-testid': "audit-sidebar",
    component: "nav",
    'aria-label': "lighthouse audit history"  ,}
  
    , audits.map(audit => (
      React.createElement(ListItem, {
        key: audit.id,
        selected: audit.id === selectedId,
        button: true,
        component: Link,
        replace: true,
        to: resolvePath(generatePath('audit/:id', { id: audit.id }), '../../'),}
      
        , React.createElement(ListItemIcon, null
          , React.createElement(AuditStatusIcon, { audit: audit,} )
        )
        , React.createElement(ListItemText, { primary: formatTime(audit.timeCreated),} )
      )
    ))
  )
);

const AuditView = ({ audit }) => {
  const classes = useStyles();
  const params = useParams() ;
  const { url: lighthouseUrl } = useApi(lighthouseApiRef);

  if (_optionalChain([audit, 'optionalAccess', _ => _.status]) === 'RUNNING') return React.createElement(Progress, null );
  if (_optionalChain([audit, 'optionalAccess', _2 => _2.status]) === 'FAILED')
    return (
      React.createElement(Alert, { severity: "error",}, "This audit failed when attempting to run after several retries. Check that your instance of lighthouse-audit-service can successfully connect to your website and try again."



      )
    );

  return (
    React.createElement(InfoCard, { variant: "fullHeight",}
      , React.createElement('iframe', {
        className: classes.iframe,
        title: `Lighthouse audit${_optionalChain([audit, 'optionalAccess', _3 => _3.url]) ? ` for ${audit.url}` : ''}`,
        src: `${lighthouseUrl}/v1/audits/${encodeURIComponent(params.id)}`,}
      )
    )
  );
};

export const AuditViewContent = () => {
  const lighthouseApi = useApi(lighthouseApiRef);
  const params = useParams() ;
  const classes = useStyles();
  const navigate = useNavigate();

  const { loading, error, value: nextValue } = useAsync( 
    async () => await lighthouseApi.getWebsiteForAuditId(params.id),
    [params.id],
  );
  let [value, setValue] = useState();
  
  useEffect(() => {
    if (!!nextValue && nextValue.url !== _optionalChain([value, 'optionalAccess', _4 => _4.url])) {
      setValue(nextValue);
    }
  }, [value, nextValue, setValue]);

  const { value: websiteValue } = useAsync(
    async () =>
      await lighthouseApi.getWebsiteList({
        limit: 100,
        offset: 0,
      }),
    [],
  );

  let content = null;
  if (value) {
    if (websiteValue && value.url.includes('code=') ) {
      value = getGroupCodeItem(websiteValue, params.id); 
    }

    content = (
      React.createElement(Grid, { container: true, alignItems: "stretch", className: classes.contentGrid,}
        , React.createElement(Grid, { item: true, xs: 3,}
          , React.createElement(AuditLinkList, { audits: _optionalChain([value, 'optionalAccess', _5 => _5.audits]), selectedId: params.id,} )
        )
        , React.createElement(Grid, { item: true, xs: 9,}
          , React.createElement(AuditView, { audit: _optionalChain([value, 'optionalAccess', _6 => _6.audits, 'access', _7 => _7.find, 'call', _8 => _8(a => a.id === params.id)]),} )
        )
      )
    );
  } else if (loading) {
    content = React.createElement(Progress, null );
  } else if (error) {
    content = (
      React.createElement(Alert, {
        'data-testid': "error-message",
        severity: "error",
        className: classes.errorOutput,}
      
        , error.message
      )
    );
  }

  let createAuditButtonUrl = 'create-audit';
  if (_optionalChain([value, 'optionalAccess', _9 => _9.url])) {
    createAuditButtonUrl += `?url=${encodeURIComponent((value.url.includes('code=')) ? value.url.split('/?')[0] : value.url)}`;
  }

  return (
    React.createElement(React.Fragment, null
      , React.createElement(ContentHeader, {
        title: (_optionalChain([value, 'optionalAccess', _10 => _10.url, 'access', _11 => _11.includes, 'call', _12 => _12('code=')])) ? _optionalChain([value, 'optionalAccess', _13 => _13.url, 'access', _14 => _14.split, 'call', _15 => _15('/?'), 'access', _16 => _16[0]]) : _optionalChain([value, 'optionalAccess', _17 => _17.url])  || 'Audit',
        description: "See a history of all Lighthouse audits for your website run through Backstage."            ,}
      
        , React.createElement(Button, {
          variant: "contained",
          color: "primary",
          onClick: () => navigate(`../../${createAuditButtonUrl}`),}
        , "Create New Audit"

        )
        , React.createElement(LighthouseSupportButton, null )
      )
      , content
    )
  );
};

const ConnectedAuditView = () => (
  React.createElement(Page, { themeId: "tool",}
    , React.createElement(Header, { title: "Lighthouse", subtitle: "Website audits powered by Lighthouse"    ,}
      , React.createElement(HeaderLabel, { label: "Owner", value: "Spotify",} )
      , React.createElement(HeaderLabel, { label: "Lifecycle", value: "Alpha",} )
    )
    , React.createElement(Content, { stretch: true,}
      , React.createElement(AuditViewContent, null )
    )
  )
);

export default ConnectedAuditView;

function getGroupCodeItem(value, id)  {
  let codeArray = []
  codeArray = value.items.filter(item => item.url.includes('code='));
  // Removes all the items that had code= in it from the original array
  codeArray.forEach(f => value.items.splice(value.items.findIndex(e => e.url === f.url),1));

  for (const item of codeArray) {
    const index = value.items.findIndex(value => value.url.split('/?')[0] === item.url.split('/?')[0]);
    if (index !== -1) {
      value.items[index].audits = value.items[index].audits.concat(item.audits);
      if (new Date(value.items[index].lastAudit.timeCreated).getTime() < new Date(item.lastAudit.timeCreated).getTime()) {
        value.items[index].lastAudit = item.lastAudit;
      }
    }
    else {
      value.items.push(item);
    }
  }
  for (const item of value.items) {
    for (const audit of item.audits) {
      if (audit.id === id) {
        return item
      }
    }
  }
  return undefined
}