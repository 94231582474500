import React from 'react';
import { Navigate, Route } from 'react-router';
import {
  AlertDisplay,
  OAuthRequestDialog, 
  SignInPage
} from '@backstage/core-components';
import { createApp, FlatRoutes } from '@backstage/core-app-api'
import { githubAuthApiRef, samlAuthApiRef } from '@backstage/core-plugin-api';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import { TechdocsPage } from '@backstage/plugin-techdocs';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { Root } from './components/Root';
import { Brightness2, WbSunny } from '@material-ui/icons'
import { 
  lightTheme,
  darkTheme,
  genPageTheme,
  shapes,
  createTheme
} from '@backstage/theme';
// import { LighthousePage } from '@backstage/plugin-lighthouse';
import { LighthousePage } from '@mitel/mc-lighthouse';

const colorVariants = {
  darkGrey: ['#171717', '#383838'],
  marineBlue: ['#006D8F', '#0049A1'],
  veryBlue: ['#0027AF', '#270094'],
  rubyRed: ['#98002B', '#8D1134'],
  toastyOrange: ['#BE2200', '#A41D00'],
  purpleSky: ['#8912CA', '#3E00EA'],
  eveningSea: ['#00FFF2', '#035355'],
  teal: ['#005B4B'],
  pinkSea: ['#C8077A', '#C2297D'],
};
const mitelColours = {
  Primary: {
    darkBlue: '#15325F',
    lightBlue: '#00A1E0',
    gray: '#808285'
  },
  Secondary: {
    lightBlue: '#BED9ED',
    green: '#41AD49',
    orange: '#FF7300',
    red: '#EB3530',
    darkBlue: '#0074CD'
  }
}

const mitelTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: mitelColours.Secondary.darkBlue,
    },
    secondary: {
      main: mitelColours.Primary.lightBlue,
    },
    error: {
      main: mitelColours.Secondary.red
    },
    warning: {
      main: mitelColours.Secondary.orange,
    },
    info: {
      main: mitelColours.Secondary.lightBlue,
    },
    success: {
      main: mitelColours.Secondary.green,
    },
    background: {
      default: '#FAFAFA',
      paper: '#FFFFFF',
    },
    banner: {
      info: mitelColours.Secondary.darkBlue,
      error: mitelColours.Secondary.red,
      text: '#FFFFFF',
      link: '#0000EE',
    },
    link: mitelColours.Secondary.darkBlue,
    errorBackground: mitelColours.Secondary.red,
    warningBackground: mitelColours.Secondary.orange,
    infoBackground: mitelColours.Secondary.darkBlue,
    navigation: {
      background: '#505050',
      indicator: '#A4B9C2',
      color: mitelColours.Secondary.lightBlue,
      selectedColor: mitelColours.Primary.lightBlue,
    },
  },
  defaultPageTheme: 'home',
  fontFamily: '"Museo Sans", Arial',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme([mitelColours.Primary.darkBlue, mitelColours.Primary.lightBlue], shapes.wave),
    documentation: genPageTheme(colorVariants.pinkSea, shapes.wave2),
    tool: genPageTheme(colorVariants.purpleSky, shapes.round),
    service: genPageTheme(colorVariants.marineBlue, shapes.wave),
    website: genPageTheme(colorVariants.veryBlue, shapes.wave),
    library: genPageTheme(colorVariants.rubyRed, shapes.wave),
    other: genPageTheme(colorVariants.darkGrey, shapes.wave),
    app: genPageTheme(colorVariants.toastyOrange, shapes.wave),
    apis: genPageTheme(colorVariants.teal, shapes.wave2),
  },
});

const app = createApp({
  apis,
  components: {
    SignInPage: props => {
      return (
        React.createElement(SignInPage, {
          ...props,
          providers: [
            {
              id: 'github-auth-provider',
              title: 'GitHub',
              message: 'Sign in using GitHub',
              apiRef: githubAuthApiRef,
            },
            {
              id: 'saml-auth-provider',
              title: 'AWS SSO',
              message: 'Sign in using AWS Single Sign-On',
              apiRef: samlAuthApiRef,
            },
          ],
          title: "Select a sign-in method"   ,
          align: "center",}
        )
      );
    },
  },
  themes: [
    {
      id: 'mitel',
      title: 'Mitel',
      variant: 'light',
      theme: mitelTheme,
      icon: React.createElement(WbSunny, null )
    },
    {
      id: 'default-dark',
      title: 'Default',
      variant: 'dark',
      theme: darkTheme,
      icon: React.createElement(Brightness2, null )
    }
  ],
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
    });
    bind(apiDocsPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
  },
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const routes = (
  React.createElement(FlatRoutes, null
    , React.createElement(Navigate, { key: "/", to: "/catalog",} )
    , React.createElement(Route, { path: "/catalog", element: React.createElement(CatalogIndexPage, null ),} )
    , React.createElement(Route, {
      path: "/catalog/:namespace/:kind/:name",
      element: React.createElement(CatalogEntityPage, null ),}
    
      , entityPage
    )
    , React.createElement(Route, { path: "/docs", element: React.createElement(TechdocsPage, null ),} )
    , React.createElement(Route, { path: "/create", element: React.createElement(ScaffolderPage, null ),} )
    , React.createElement(Route, { path: "/api-docs", element: React.createElement(ApiExplorerPage, null ),} )
    , React.createElement(Route, { path: "/lighthouse", element: React.createElement(LighthousePage, null ),} )
    , React.createElement(Route, {
      path: "/tech-radar",
      element: React.createElement(TechRadarPage, { width: 1500, height: 800,} ),}
    )
    , React.createElement(Route, { path: "/catalog-import", element: React.createElement(CatalogImportPage, null ),} )
    , React.createElement(Route, { path: "/search", element: React.createElement(SearchPage, null ),}  )
    , React.createElement(Route, { path: "/settings", element: React.createElement(UserSettingsPage, null ),} )
  )
);

const App = () => (
  React.createElement(AppProvider, null
    , React.createElement(AlertDisplay, null )
    , React.createElement(OAuthRequestDialog, null )
    , React.createElement(AppRouter, null
      , React.createElement(Root, null, routes)
    )
  )
);

export default App;
