 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useEntity } from '@backstage/plugin-catalog-react';
import AuditList from './components/AuditList';
import AuditView, { AuditViewContent } from './components/AuditView';
import CreateAudit, { CreateAuditContent } from './components/CreateAudit';

import { LIGHTHOUSE_WEBSITE_URL_ANNOTATION } from './constants';
import { AuditListForEntity } from './components/AuditList/AuditListForEntity';
import { MissingAnnotationEmptyState } from '@backstage/core-components';

export const isLighthouseAvailable = (entity) =>
  Boolean(_optionalChain([entity, 'access', _ => _.metadata, 'access', _2 => _2.annotations, 'optionalAccess', _3 => _3[LIGHTHOUSE_WEBSITE_URL_ANNOTATION]]));

export const Router = () => (
  React.createElement(Routes, null
    , React.createElement(Route, { path: "/", element: React.createElement(AuditList, null ),} )
    , React.createElement(Route, { path: "/audit/:id", element: React.createElement(AuditView, null ),} )
    , React.createElement(Route, { path: "/create-audit", element: React.createElement(CreateAudit, null ),} )
  )
);






export const EmbeddedRouter = (_props) => {
  const { entity } = useEntity();

  if (!isLighthouseAvailable(entity)) { // Breaking here since entity is coming in as undefined
    return (
      React.createElement(MissingAnnotationEmptyState, {
        annotation: LIGHTHOUSE_WEBSITE_URL_ANNOTATION,}
      )
    );
  }

  return (
    React.createElement(Routes, null
      , React.createElement(Route, { path: "/", element: React.createElement(AuditListForEntity, null ),} )
      , React.createElement(Route, { path: "/audit/:id", element: React.createElement(AuditViewContent, null ),} )
      , React.createElement(Route, { path: "/create-audit", element: React.createElement(CreateAuditContent, null ),} )
    )
  );
};
