/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';
// import mitelLogo from './mitel-images/MitelLogo-whiteblue-withR.png'

const useStyles = makeStyles({
  svg: {
    width: 200,
    height: 90,
  }
});

const st1 = makeStyles({
  path: {
    fill: '#16A0DB'
  }
});
const st2 = makeStyles({
  path: {
    fill: '#ffffff'
  }
});

const LogoFull = () => {
  // return <img src={mitelLogo} width='auto' height='30' />
  const classes = useStyles();
  const classSt1 = st1();
  const classSt2 = st2();

  return (
    React.createElement('svg', {
      className: classes.svg,
      xmlns: "http://www.w3.org/2000/svg",
      xmlnsXlink: "http://www.w3.org/1999/xlink",
      viewBox: "0 0 72 72"   ,}
    
    , React.createElement('g', { transform: "translate(-35, 15)" ,}
      , React.createElement('path', { className: classSt1.path, d: "M32.9,31.3l-3.3-1.9l-1-0.6c-0.4-0.3-0.7-0.8-0.7-1.3c0-0.5,0.3-1,0.7-1.3l1-0.6l-3.2-1.9 c-1.1,0.9-1.8,2.3-1.8,3.8c0,1.5,0.7,2.9,1.7,3.8c0.2,0.2,0.4,0.3,0.6,0.4l2.7,1.6h0l9,5.2c0.7,0.4,1.6,0.7,2.5,0.7 c2.8,0,5-2.2,5-5V20.9c0-2.8-2.2-5-5-5c-0.9,0-1.8,0.3-2.5,0.7l-9,5.2l2.7,1.6c0.2,0.1,0.4,0.3,0.6,0.4l7.4-4.3 c0.2-0.2,0.5-0.2,0.9-0.2c0.9,0,1.7,0.7,1.7,1.7v13.3c0,0.9-0.7,1.7-1.7,1.7c-0.3,0-0.6-0.1-0.8-0.2L32.9,31.3"


        ,})
      , React.createElement('path', { className: classSt2.path, d: "M97.5,32.3v-9.8h4.7v-3.2h-4.7v-4.4h-3.3v17.4c0,5.2,3.3,8,8.1,7.5v-3.1C99.7,36.8,97.5,35.9,97.5,32.3z",})
      , React.createElement('circle', { className: classSt1.path, cx: "88.3", cy: "14.9", r: "2",})
      , React.createElement('path', { className: classSt2.path, d: "M130.3,33V11.5h-3.4V33c0,3.8,3.1,6.8,6.8,6.8v-3.4C131.8,36.4,130.3,34.9,130.3,33z",})
      , React.createElement('polygon', { className: classSt2.path, points: "77.8,13.1 69.6,32.9 61.5,13.1 56.6,13.1 56.6,39.4 60.1,39.4 60.1,17.8 69,39.4 70.3,39.4 79.3,17.8  79.3,39.4 82.6,39.4 82.6,13.1 \t"
           ,})
      , React.createElement('g', null
        , React.createElement('path', { className: classSt2.path, d: "M29.7,25.6l1,0.6c0.4,0.3,0.7,0.8,0.7,1.3c0,0.5-0.3,1-0.7,1.3l-1,0.6l3.3,1.9c1.1-0.9,1.7-2.3,1.7-3.8 c0-1.5-0.7-2.9-1.8-3.8c-0.2-0.2-0.4-0.3-0.6-0.4l-2.7-1.6l0,0l-9-5.2c-0.7-0.4-1.6-0.7-2.5-0.7c-2.8,0-5,2.2-5,5v13.3 c0,2.8,2.2,5,5,5c0.9,0,1.8-0.2,2.5-0.7l9-5.2L27,31.8c-0.2-0.1-0.4-0.3-0.6-0.4L19,35.6c-0.2,0.1-0.5,0.2-0.8,0.2 c-0.9,0-1.7-0.7-1.7-1.7V20.9c0-0.9,0.7-1.7,1.7-1.7c0.3,0,0.6,0.1,0.9,0.2l7.4,4.3l0,0L29.7,25.6z"


          ,})
      )
      , React.createElement('rect', { x: "86.6", y: "18.9", className: classSt2.path, width: "3.4", height: "20.5",})
      , React.createElement('path', { className: classSt2.path, d: "M114,37.2c-3.4,0-6.7-2.5-7.1-6.8h17.2c0-7.3-5.1-11.7-10.4-11.7c-5.7,0-10.3,4.9-10.3,10.8 c0,6,4.6,10.7,10.5,10.7c4.7,0,8.2-2.5,9.7-6.9H120C118.9,35.8,116.9,37.2,114,37.2z M113.7,21.8c3,0,5.8,2.2,6.6,5.6H107 C107.9,23.9,110.9,21.8,113.7,21.8z"

        ,})
    )
    )
  );
};

export default LogoFull;
