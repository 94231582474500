/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Button,
  Grid,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { lighthouseApiRef } from '../../api';
import { useQuery } from '../../utils';
import LighthouseSupportButton from '../SupportButton';

import {
  Content,
  ContentHeader,
  Header,
  HeaderLabel,
  InfoCard,
  Page,
} from '@backstage/core-components';
import { configApiRef, errorApiRef, useApi } from '@backstage/core-plugin-api';

// TODO(freben): move all of this out of index

const useStyles = makeStyles(theme => ({
  input: {
    minWidth: 300,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
  buttonList: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    '& > *': {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
      flexDirection: 'column',
      '& > *': {
        width: '100%',
      },
    },
  },
}));

export const CreateAuditContent = () => {
  const errorApi = useApi(errorApiRef);
  const lighthouseApi = useApi(lighthouseApiRef);
  const config = useApi(configApiRef)
  const classes = useStyles();
  const query = useQuery();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  let [url, setUrl] = useState(query.get('url') || '');
  const [emulatedFormFactor, setEmulatedFormFactor] = useState('mobile');

  const triggerAudit = useCallback(async () => {
    setSubmitting(true);
    try {
      if (url.includes('client_id')) {
        const clDevAccountId = config.getString('lighthouse.lhclSecrets.clDevAccountId');
        const clPubAccountId = config.getString('lighthouse.lhclSecrets.clPubAccountId');
        const clUsername = config.getString('lighthouse.lhclSecrets.clUsername');
        const clPassword = config.getString('lighthouse.lhclSecrets.clPassword');
        const clClientId = url.split('=')[1]; // url returns https://meet.mitel.io?client_id=1234 we want the 1234
        url = url.split('?')[0];

        const accountId = url.includes('.dev') ? clDevAccountId : clPubAccountId;
        const authUrl = url.includes('.dev') ? `https://authentication.dev.api.mitel.io/2017-09-01/authorize` : `https://authentication.api.mitel.io/2017-09-01/authorize`;

        const resp = await fetch(`${authUrl}`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            grantType: 'authorization_code',
            account_id: accountId,
            username: clUsername,
            password: clPassword,
            client_id: clClientId,
            response_type: 'code',
            redirect_uri: `${url}/`
          })
        })
  
        await resp.json().then(data => {
          const cloudlinkAuthToken = data.code;
          query.set('code', cloudlinkAuthToken);
        })

        url = `${url}/?${query.toString()}`
      }

      // TODO use the id from the response to redirect to the audit page for that id when
      // FAILED and RUNNING audits are supported
      await lighthouseApi.triggerAudit({ 
        url: url.replace(/\/$/, ''),    
        options: {               
          lighthouseConfig: {
            settings: {
              emulatedFormFactor,
            },
          },
        },
      });
      navigate('..');
    } catch (err) {
      errorApi.post(err);
    } finally {
      setSubmitting(false);
    }
  }, [
    url,
    emulatedFormFactor,
    lighthouseApi,
    setSubmitting,
    errorApi,
    navigate,
  ]);

  const clientIdUrls = [
    {url: 'https://meet.mitel.io', clientId: ''}, // Can I put client_id into the code? Or is it a secret?
    {url: 'https://meet.dev.mitel.io', clientId: ''},
  ]

  return (
    React.createElement(React.Fragment, null
      , React.createElement(ContentHeader, {
        title: "Trigger a new audit"   ,
        description: "Submitting this form will immediately trigger and store a new Lighthouse audit. Trigger audits to track your website's accessibility, performance, SEO, and best practices over time."                         ,}
      
        , React.createElement(LighthouseSupportButton, null )
      )
      , React.createElement(Grid, { container: true, spacing: 3,}
        , React.createElement(Grid, { item: true, xs: 12, sm: 6,}
          , React.createElement(InfoCard, null
            , React.createElement('form', {
              onSubmit: ev => {
                ev.preventDefault(); 
                triggerAudit();
              },}
            
              , React.createElement(List, null
                , React.createElement(ListItem, null
                  , React.createElement(TextField, {
                    name: "lighthouse-create-audit-url-tf",
                    className: classes.input,
                    label: "URL",
                    placeholder: "https://spotify.com",
                    helperText: "The target URL for Lighthouse to use."      ,
                    required: true,
                    disabled: submitting,
                    onChange: ev => setUrl(ev.target.value),
                    value: url,
                    inputProps: { 'aria-label': 'URL' },}
                  )
                )
                , React.createElement(ListItem, null
                  , React.createElement(TextField, {
                    name: "lighthouse-create-audit-emulated-form-factor-tf",
                    className: classes.input,
                    label: "Emulated Form Factor"  ,
                    helperText: "Device to simulate when auditing"    ,
                    select: true,
                    required: true,
                    disabled: submitting,
                    onChange: ev => setEmulatedFormFactor(ev.target.value),
                    value: emulatedFormFactor,
                    inputProps: { 'aria-label': 'Emulated form factor' },}
                  
                    , React.createElement(MenuItem, { value: "mobile",}, "Mobile")
                    , React.createElement(MenuItem, { value: "desktop",}, "Desktop")
                  )
                )
                , React.createElement(ListItem, { className: classes.buttonList,}
                  , React.createElement(Button, {
                    variant: "outlined",
                    color: "primary",
                    onClick: () => navigate('..'),
                    disabled: submitting,}
                  , "Cancel"

                  )
                  , React.createElement(Button, {
                    variant: "contained",
                    color: "primary",
                    type: "submit",
                    disabled: submitting,}
                  , "Create Audit"

                  )
                )
              )
            )
          )
        )
        , React.createElement(Grid, { item: true, xs: 12, sm: 6,}
          , React.createElement(InfoCard, null
            , React.createElement(TableContainer, null 
              , React.createElement(Table, null
                , React.createElement(TableHead, null
                  , React.createElement(TableRow, null
                    , React.createElement(TableCell, null, "CloudLink URL" )
                    , React.createElement(TableCell, { align: "right",}, "client_id")
                  )
                )
                , React.createElement(TableBody, null
                  , clientIdUrls.map((row) => (
                    React.createElement(TableRow, { key: row.url,}
                      , React.createElement(TableCell, { component: "th", scope: "row",}, row.url)
                      , React.createElement(TableCell, { align: "right",}, row.clientId)
                    )
                  ))
                )
              )
             )
          )
        )
      )
    )
  );
};

const CreateAudit = () => (
  React.createElement(Page, { themeId: "tool",}
    , React.createElement(Header, { title: "Lighthouse", subtitle: "Website audits powered by Lighthouse"    ,}
      , React.createElement(HeaderLabel, { label: "Owner", value: "Spotify",} )
      , React.createElement(HeaderLabel, { label: "Lifecycle", value: "Alpha",} )
    )
    , React.createElement(Content, null
      , React.createElement(CreateAuditContent, null )
    )
  )
);

export default CreateAudit;
