/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useContext, } from 'react';
import { Link, makeStyles, SvgIcon } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Sidebar,
  SidebarPage,
  sidebarConfig,
  SidebarContext,
  SidebarItem,
  SidebarDivider,
  SidebarSpace,
} from '@backstage/core-components';
import { NavLink } from 'react-router-dom';
import { Settings as SidebarSettings } from '@backstage/plugin-user-settings';
import { SidebarSearch } from '@backstage/plugin-search';

function LightHouseIcon() {
  return (
    React.createElement(SvgIcon, null 
      , React.createElement('path', { d: "M8 10V8h1V4H8V3l4-2l4 2v1h-1v4h1v2h-1.26l-6.3 3.64L9 10H8m5-2V4h-2v4h2M7 23l.04-.24l9.11-5.26l.52 3.38L13 23H7m1.05-6.83L15.31 12l.52 3.37l-8.4 4.85l.62-4.05z"          ,} )
    )
  )
}

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 5,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useContext(SidebarContext);

  return (
    React.createElement('div', { className: classes.root,}
      , React.createElement(Link, {
        component: NavLink,
        to: "/",
        underline: "none",
        className: classes.link,}
      
        , isOpen ? React.createElement(LogoFull, null ) : React.createElement(LogoIcon, null )
      )
    )
  );
};

export const Root = ({ children }) => (
  React.createElement(SidebarPage, null
    , React.createElement(Sidebar, null
      , React.createElement(SidebarLogo, null )
      , React.createElement(SidebarSearch, null )
      , React.createElement(SidebarDivider, null )
      /* Global nav, not org-specific */
      , React.createElement(SidebarItem, { icon: HomeIcon, to: "/catalog", text: "Home",} )
      , React.createElement(SidebarItem, { icon: ExtensionIcon, to: "api-docs", text: "APIs",} )
      , React.createElement(SidebarItem, { icon: LibraryBooks, to: "docs", text: "Docs",} )
      , React.createElement(SidebarItem, { icon: LightHouseIcon, to: "lighthouse", text: "Lighthouse",} )
      , React.createElement(SidebarItem, { icon: CreateComponentIcon, to: "create", text: "Create...",} )
      /* End global nav */
      , React.createElement(SidebarDivider, null )
      , React.createElement(SidebarItem, { icon: MapIcon, to: "tech-radar", text: "Tech Radar" ,} )
      , React.createElement(SidebarSpace, null )
      , React.createElement(SidebarDivider, null )
      , React.createElement(SidebarSettings, null )
    )
    , children
  )
);
